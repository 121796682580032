@import "tailwind.css";
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    margin-bottom: 10px;
}
a:link {
    color: blue;
    text-decoration: none;
}
a:visited {
    color: purple;
    text-decoration: none;
}
a:hover {
    color: hotpink;
    text-decoration: none;
}
a:active {
    color: blue;
    text-decoration: none;
}
.myLink:link {
    color: white;
    font-weight: bolder;
    text-decoration: none;
}
.myLink:visited {
    color: lightgrey;
    font-weight: bold;
    text-decoration: none;
}
.myLink:hover {
    color: hotpink;
    font-weight: bold;
    text-decoration: none;
}
.myLink.contrast:link {
    color: black;
}
.myLink.contrast:visited {
    color: violet;
}
.myLink.contrast:hover {
    color: darkviolet;
}

.myLink:active {
    color: blue;
    font-weight: bold;
    text-decoration: none;
}
h1 {
    margin-top: 5px;
    font-size: 45px;
}
table {
    width: 99%;
    border-collapse: collapse;
    margin-top: 5px;
    font-size: 12px;
}
th, td {
    padding: 5px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}
tr:nth-child(even) {
    background-color: #f2f2f2;
}
.pagination {
    text-align: center;
}
.chart_container {
    display: flex;
    flex-grow: 1;
}
#status_chart_div, #newopen_sla_chart_div, #closed_sla_chart_div {
    width: 33%;
    height: 250px;
}
#sla_stats {
    width: 30%;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
}
#filters {
    width: 80%;
    margin-top: 25px;
    align-items: center;
    height: auto;
    text-align: center;
}
.count {
    font-size: 20px;
    font-weight: bold;
}
#report_chart_pie, #report_chart_bar, #report_chart_column {
    width: 33%;
    height: 300px;
}

.chart_container svg > rect { fill: #F3F4F6 !important; }
